var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"pb-5"},[_c('back-button')],1),_c('page-heading',{attrs:{"hideTVButton":"","slug":"polls","placeholder":"Umfrage","currentSession":_vm.currentSession}}),_c('nav',{staticClass:"mt-8 flex w-1/2 justify-between",attrs:{"aria-label":"Tabs"}},[_c('button',{class:[
        _vm.computedPollLabelTabState === 0
          ? 'bg-primary bg-opacity-10 text-primary'
          : 'text-gray-500 hover:text-gray-700',
        'px-3 py-2 font-medium text-sm rounded-md focus:outline-none focus:border-none' ],attrs:{"aria-current":_vm.computedPollLabelTabState === 0 ? 'page' : undefined},on:{"click":function($event){return _vm.selectPollLabelTab(0, false)}}},[_vm._v(" "+_vm._s(_vm.$t('views.presenter.session.pollsSettingsShowPoll'))+" ")]),_c('button',{class:[
        _vm.computedPollLabelTabState === 1
          ? 'bg-primary bg-opacity-10 text-primary'
          : 'text-gray-500 hover:text-gray-700',
        'px-3 py-2 font-medium text-sm rounded-md focus:outline-none focus:border-none' ],attrs:{"aria-current":_vm.computedPollLabelTabState === 1 ? 'page' : undefined},on:{"click":function($event){return _vm.selectPollLabelTab(1, true)}}},[_vm._v(" "+_vm._s(_vm.$t('views.presenter.session.pollsSettingsHidePoll'))+" ")])]),_c('nav',{staticClass:"mt-8 flex w-1/2 justify-between",attrs:{"aria-label":"Tabs"}},[_c('button',{class:[
        _vm.computedPollVoteState === 0
          ? 'bg-primary bg-opacity-10 text-primary'
          : 'text-gray-500 hover:text-gray-700',
        'px-3 py-2 font-medium text-sm rounded-md focus:outline-none focus:border-none' ],attrs:{"aria-current":_vm.computedPollVoteState === 0 ? 'page' : undefined},on:{"click":function($event){return _vm.selectPollVoteTab(0)}}},[_vm._v(" "+_vm._s(_vm.$t('views.presenter.session.pollsSettingsPercentVote'))+" ")]),_c('button',{class:[
        _vm.computedPollVoteState === 1
          ? 'bg-primary bg-opacity-10 text-primary'
          : 'text-gray-500 hover:text-gray-700',
        'px-3 py-2 font-medium text-sm rounded-md focus:outline-none focus:border-none' ],attrs:{"aria-current":_vm.computedPollVoteState === 1 ? 'page' : undefined},on:{"click":function($event){return _vm.selectPollVoteTab(1)}}},[_vm._v(" "+_vm._s(_vm.$t('views.presenter.session.pollsSettingsPercent'))+" ")]),_c('button',{class:[
        _vm.computedPollVoteState === 2
          ? 'bg-primary bg-opacity-10 text-primary'
          : 'text-gray-500 hover:text-gray-700',
        'px-3 py-2 font-medium text-sm rounded-md focus:outline-none focus:border-none' ],attrs:{"aria-current":_vm.computedPollVoteState === 2 ? 'page' : undefined},on:{"click":function($event){return _vm.selectPollVoteTab(2)}}},[_vm._v(" "+_vm._s(_vm.$t('views.presenter.session.pollsSettingsVote'))+" ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }