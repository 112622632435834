















































































import Vue from 'vue';
import { mapGetters } from 'vuex';
import PageHeading from '@/components/Presenter/PageHeading.vue';
import BackButton from '@/components/Presenter/BackButton.vue';

export default Vue.extend({
  components: { PageHeading, BackButton },

  data() {
    return {
      drag: false,
      sortedQuestions: [],
    };
  },
  computed: {
    ...mapGetters({
      currentSession: 'presenter/getCurrentSession',
    }),
    computedPollLabelTabState() {
      if (this.currentSession) {
        return this.currentSession.hidePollLabels ? 1 : 0;
      }
      return 0;
    },
    computedPollVoteState() {
      if (this.currentSession) {
        return this.currentSession.voteResultType || 0;
      }
      return 0;
    },
  },
  methods: {
    selectPollLabelTab(id: string, value: boolean) {
      this.$store.dispatch('presenter/togglePollLabelState', value);
    },
    selectPollVoteTab(id: string) {
      this.$store.dispatch('presenter/updatePollVoteResultType', id);
    },
  },
});
